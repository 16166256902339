/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { useCookies } from "react-cookie";

export default function LogoutModal() {
  const [show, setShow] = useState(true)
  const [cookies, setCookie, removeCookie] = useCookies(['dauth-token']);

  function handleLogout(){
    setShow(false)
    removeCookie('dauthJWT')
    window.location.reload()
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-20 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 divide-x divide-gray-200">
              <div className="w-0 flex-1 flex items-center p-4">
                <div className="w-full">
                  <p className="text-sm font-medium text-gray-900">Logout</p>
                  <p className="mt-1 text-sm text-gray-500">Logout from the example demo.</p>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col divide-y divide-gray-200">
                  <div className="h-0 flex-1 flex">
                    <button
                      className="w-full border border-transparent rounded-none rounded-tr-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-red-600 hover:text-red-400 focus:outline-none focus:z-10 focus:ring-2 focus:ring-slate-900"
                      onClick={() => {
                        handleLogout()
                      }}
                    >
                      Logout
                    </button>
                  </div>
                  <div className="h-0 flex-1 flex">
                  <button
                      className="w-full border border-transparent rounded-none rounded-br-lg px-4 py-3 flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-slate-900"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      Hide
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}
